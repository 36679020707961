import React from "react";
import Article from "shared/Article.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import { ChampionImageContainer } from "@/feature-reskin-probuilds-net/ProBuildsArticle.style.jsx";
import Static from "@/game-lol/utils/static.mjs";
import useStaticChampionByKey from "@/game-lol/utils/use-static-champion-by-id.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

type StaticChampion = {
  id: number;
  name: string;
  title: string;
};

function LolChampionHeader({
  championId,
  championName,
  championTitle,
}: {
  championId: number;
  championName: string;
  championTitle: string;
}) {
  return (
    <ChampionImageContainer>
      <img
        src={Static.getChampionCenterImage(championId) ?? undefined}
        width={1068}
        height={600}
        alt={championName}
      />
      <div className="champion-name-container">
        <h2 className="champion-name">{championName}</h2>
        {championTitle && <h3 className="champion-title">{championTitle}</h3>}
      </div>
    </ChampionImageContainer>
  );
}

export function ProBuildsArticle() {
  const { parameters } = useRoute();
  const articleSlug = parameters[0];

  const champion: StaticChampion | undefined = useStaticChampionByKey(
    (articleSlug || "").split("-")[0],
  );

  return (
    <Layout>
      {champion?.id ? (
        <Article
          headerImageComponent={
            <LolChampionHeader
              championId={champion.id}
              championName={champion.name}
              championTitle={champion.title}
            />
          }
        />
      ) : (
        <Article />
      )}
    </Layout>
  );
}

ProBuildsArticle.showAppNavigation = true;

export default ProBuildsArticle;

export function meta([id]): Meta {
  const {
    contentful: { entry, canonical },
  } = readState;
  const article = entry[id] ?? entry[canonical[id]];
  // This is mandatory now in Contentful but there may be some articles that were created with this as an optional field
  if (
    typeof article?.seoTitle === "string" &&
    typeof article?.seoDescription === "string"
  ) {
    // Localization for an article is handled on Contentful and returns translatable content, regardless of domain
    return {
      title: ["", article.seoTitle],
      description: ["", article.seoDescription],
    };
  }

  return {
    title: [
      "probuilds:title.proBuilds",
      "{{id}} - Article by Probuilds",
      { id },
    ],
    description: [
      "contentful:homepage.meta.description",
      "Master {{friendlyName}} with expert tips, in-depth guides, and winning strategies from Blitz.gg. Whether you're a beginner or a pro, we've got everything you need to level up your game!",
      { friendlyName: id },
    ],
  };
}
