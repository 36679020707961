import { styled } from "goober";

export const ChampionImageContainer = styled("div")`
  & {
    container-type: inline-size;
    container-name: champion-image-container;
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1056 / 432;
    overflow: clip;
    border-radius: var(--br-xl);

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: var(--br-xl);
      border: 2px solid var(--shade0-15);
      pointer-events: none;
    }
  }

  img {
    position: absolute;
    top: -10%;
    width: 100%;
    height: auto;
    aspect-ratio: 1068 / 600;
    object-fit: cover;
    mask-image: radial-gradient(
      40% 30% at 50% 30%,
      hsl(0deg 0% 0%) 0%,
      hsl(0deg 0% 100% / 20%) 100%
    );

    @container (width <= 900px) {
      aspect-ratio: 1068 / 800;
    }
  }

  .champion-name-container {
    position: absolute;
    bottom: 8%;
    left: 0;
    width: 100%;
    text-align: center;
    text-shadow: 0 6px 15px var(--shade10);
  }

  .champion-name {
    font-size: 3.875rem;
    font-weight: 900;
    letter-spacing: 0.5rem;
    line-height: 1.3;
    text-transform: uppercase;

    @container (width <= 900px) {
      font-size: 3.25rem;
    }
    @container (width <= 600px) {
      font-size: 2rem;
    }
  }

  .champion-title {
    font-size: 1.125rem;
    font-weight: 300;
    color: hsl(0deg 0% 100% / 0.6);
  }
`;
